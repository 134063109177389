<template>
  <b-nav-item-dropdown class="dropdown-cart mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="$store.state[prefix_store].cartItemsCount" class="text-body" icon="ShoppingCartIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">My Cart</h4>
        <b-badge pill variant="light-primary"> {{ $store.state[prefix_store].cartItemsCount }} Items </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="$store.state[prefix_store].cartsNav.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media v-for="item in $store.state[prefix_store].cartsNav" :key="item.id">
        <template #aside>
          <b-img :src="item.image" :alt="item.name" rounded width="62px" />
        </template>
        <feather-icon icon="XIcon" class="cart-item-remove cursor-pointer" @click.stop="removeItem(item)" />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.name }}
            </b-link>
          </h6>
          <!-- <small class="cart-item-by"> {{ item.brand }}</small> -->
        </div>

        <div class="cart-item-qty ml-1">
          <!-- <b-form-spinbutton v-model="item.qty" min="1" size="sm" /> -->
          {{ item.qty }}
        </div>

        <h5 class="cart-item-price">{{ __formatMoney(item.price) }}</h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li v-if="$store.state[prefix_store].cartsNav.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">الاجمالي:</h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ __formatMoney(totalAmount) }}
        </h6>
      </div>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block :to="{ name: `cataloge-checkout` }">
        الدفع
      </b-button>
    </li>

    <p v-if="!$store.state[prefix_store].cartsNav.length" class="m-0 p-1 text-center">Your cart is empty</p>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { useEcommerceUi, useCataloge } from "@/views/front/cataloge/useCataloge.js";

import { isUserLoggedIn } from "@/auth/utils";
//apps-e-commerce-checkout
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton
  },
  directives: {
    Ripple
  },
  data() {
    return {
      items: [],
      // prefix_store:"app-ecommerce",
      prefix_store: "cataloge",
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false
      }
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.$store.state[this.prefix_store].cartsNav.forEach(i => {
        total += i.price;
      });
      return total;
    }
  },
  mounted() {
    // this.fetchItems();
  },
  methods: {
    fetchItems() {
      var prefix = "app-ecommerce";
      var prefix = "cataloge";
      // this.$store
      //   .dispatch(`${prefix}/fetchCartProducts`, { from_nav: true })
      //   .then((response) => {
      //     this.items = response.data.products;
      //     this.$store.commit(
      //       "cataloge/UPDATE_CART_ITEMS_COUNT",
      //       this.items.length
      //     );
      //   });
    }
    // removeItemFromCart(productId) {
    //   var prefix = "app-ecommerce";
    //   var prefix = "cataloge";
    //   this.$store
    //     .dispatch(`${prefix}/removeProductFromCart`, { productId })
    //     .then(() => {
    //       const itemIndex = this.items.findIndex((p) => p.id === productId);
    //       this.items.splice(itemIndex, 1);

    //       // Update count in cart items state
    //       this.$store.commit(
    //         `${prefix}/UPDATE_CART_ITEMS_COUNT`,
    //         this.items.length
    //       );
    //     });
    // },
  },
  setup() {
    const { fetchAllCarts } = useEcommerceUi();
    const { removeItemFromCart } = useCataloge();
    const isLogin = isUserLoggedIn();
    const removeItem = item => {
      removeItemFromCart(item.id).then(res => {
        fetchAllCarts();
      });
    };

    if (isLogin) {
      fetchAllCarts();
    }

    return {
      removeItem
    };
  }
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
