/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'domains', route: { name: 'apps-domain-index' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'subdomains', route: { name: 'apps-subdomain-index' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'controls', route: { name: 'apps-control-index' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'users', route: { name: 'apps-user-index' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'roles', route: { name: 'apps-role-index' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'cities', route: { name: 'apps-city-index' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'countries', route: { name: 'apps-country-index' }, icon: 'UserIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: '',
        from: '',
        icon: require('@/assets/images/icons/pdf.png'),
        size: '1.5 mb',
      },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        name: '',
        email: '',
        time: '',
      },
     
    ],
  },
}
/* eslint-enable */
