<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    v-if="currentUser"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name name-weight-bolder mb-0">
          {{ currentUser.name }}
        </p>
        <!-- <span class="user-status">{{ currentUser.role_name }}</span> -->
      </div>
      <b-avatar size="40" :src="currentUser.photo" variant="light-primary" badge class="badge-minimal" badge-variant="success">
        <feather-icon v-if="!currentUser.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item v-if="currentUser.id" :to="{ name: 'user.profile' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>الملف الشخصى</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item> -->

    <b-dropdown-item link-class="d-flex align-items-center" v-if="isLoggedIn" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>خروج</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" v-if="!isLoggedIn" :to="{ name: 'auth-login' }">
      <feather-icon size="16" icon="ChevronLeftIcon" class="mr-50" />
      <span>تسجيل دخول</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      isLoggedIn: isUserLoggedIn()
    };
  },
  computed: {
    //  AppSetting(){
    //   return this.$store.getters['setting/AppSetting'];
    // },
    ...mapGetters([
      "currentUser"
      //   "currentUserRole",
    ])
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      //  this.logout();
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      //   localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // Reset ability
      //   this.$ability.update(initialAbility);

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      //  window.location.href='/login';
    }
  }
};
</script>
