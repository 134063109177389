<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>

    <!--
     @click="$i18n.locale = localeObj.locale"
     @click="lang(localeObj.locale)"
         to="/lang/{localeObj.locale}"
   -->
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="lang(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // data() {
  //   return {
  //     locale: this.$i18n.locale,
  //   }
  // },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  // watch: {
  // locale(val) {
  //   this.$i18n.locale = val
  // },
  // },
  methods: {
    lang(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      const isRTL = lang === 'ar'
      document.documentElement.setAttribute('lang', lang)
      document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
      // this.$store.state.appConfig.layout.isRTL=isRTL;
      this.$http.get(`/swaplang/${lang}`)
        .then(response => {
          console.log(response.data)
        })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'ar',
        img: require('@/assets/images/flags/sa.svg'),
        // name: process.env.VUE_APP_TITLE,
        name: 'عربي',
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/us.svg'),
        name: 'English',
      },

    ]
    return {
      locales,
    }
  },

}
</script>
