
// NavigationIcon
//          ListIcon
//          MenuIcon
export default [
  // {
  //   title: 'lang.dashboard',
  //   route: 'apps-dashboard',
  //   icon: 'HomeIcon',
  //   resource: 'dashboard',
  //   action: 'index',
  // },
  {
    title: 'lang.home',
    route: 'front.Home',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'index',
  },
  // {
  //   title: 'lang.catalog_plural',
  //   icon: 'ListIcon',
  //   route: 'catalogs.index',
  //   parentName: 'App',
  //   resource: 'catalogs',
  //   action: 'index',

  // },


  //  {
  //     title: 'lang.product_plural',
  //     icon: 'MoreHorizontalIcon',
  //   //  route: 'appcategories',
  //     active: false,
  //    // resource: 'appcategories',
  //     // action: 'index',
  //      submenu:true,
  //     children: [

  //       {
  //         title: 'lang.category_plural',
  //         icon: 'ListIcon',
  //         route: 'categories.index',
  //         parentName: 'App',
  //         resource: 'categories',
  //         action: 'index', 

  //     },







  //     ],
  //   },

  {
    title: 'lang.products',
    icon: 'ListIcon',
    route: 'admin.products.index',
    parentName: 'appcategories',
    guard: 'supplier',
    resource: 'products',
    action: 'index',

  },

  {
    title: 'lang.orders',
    icon: 'ListIcon',
    icon_type: 'svg',
    route: 'admin.orders.index',
    parentName: 'apporders',
    resource: 'orders',
    action: 'index',

  },

  {
    title: 'lang.wallets',
    icon: 'ListIcon',
    icon_type: 'svg',
    route: 'admin.wallets.index',
    parentName: 'wallets',
    resource: 'wallets',
    action: 'index',

  },


  // {
  //   title: 'lang.profile',
  //   icon: 'UserIcon',
  //   route: 'businesses.profile',
  //   name: 'businesses.profile',
  //   resource: 'businesses',
  //   action: 'profile',

  // },



]



/*    {
        title: 'lang.config_plural',
        icon: 'i-Library-2',
        route: 'configs.index',
        parentName: 'App',
        resource: 'configs',
        action: 'index', 

    },

    */

/*  

    */

/*

    */

/*    {
        title: 'lang.chat_bot_plural',
        icon: 'i-Library-2',
        route: 'chatBots.index',
        parentName: 'App',
        resource: 'chatBots',
        action: 'index', 

    },

    */
