<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="total"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('lang.newNotification') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ total }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!--  :to="notification.action_url" -->
      <!-- Account Notification  v-on:click="markAsRead(notification.id)" -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"

        @click="markAsRead(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.icon"
              :text="notification.icon"
              variant="light-success"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.body }}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <!--       <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>  -->
      <!--       <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="markAllRead"
    > {{ $t('lang.Read_all_notifications') }}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import router from '@/router'

const userData = getUserData()
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    total: 0,
    notifications: [],
    alart: new Audio(`${APP_URL}/public/sounds/alert-notifcation.mp3`),
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    },
  }),

  computed: {
    hasUnread() {
      return this.total > 0
    },
  },

  mounted() {
    this.fetch()
    // console.log('int');
    if (window.Echo) {
      this.listen()
    }
  },

  methods: {
    /**
     * Fetch notifications.
     *
     * @param {Number} limit
     */
    fetch(limit = 5) {
      axios.get('/get-notifications', { params: { limit } })
        .then(({ data: { total, notifications } }) => {
          this.total = total
          this.notifications = notifications.map(({ id, data, created }) => {
            const d = new Date(created)

            return {
              id,
              title: data.title,
              body: data.body,
              icon: data.icon,
              created: d.toLocaleString(),
              action_url: data.action_url,
            }
          })
        })
    },

    /**
     * Mark the given notification as read.
     *
     * @param {Object} notification
     */
    markAsRead(notification) {
      console.log(notification.action_url)
      if (notification.action_url) {
        router.push(notification.action_url)
      }
      // const { id } = notification
      // const index = this.notifications.findIndex(n => n.id === id)

      // if (index > -1) {
      //   // this.total--
      //   // this.notifications.splice(index, 1)
      //   // axios.patch(`/notifications/${id}/read`)
      //   if (notification.action_url) {
      //     router.push(notification.action_url)
      //   }
      // }
    },

    /**
     * Mark all notifications as read.
     */
    markAllRead() {
      this.total = 0
      this.notifications = []

      axios.post('/notifications/mark-all-read')
    },

    /**
     * Listen for Echo push notifications.
     */
    listen() {
      if (!userData) return
      const self = this
      window.Echo.private(`App.Models.User.${userData.id}`)
        .notification(notification => {
          this.total++
          this.notifications.unshift(notification)
          return this.alart.play()
        })
        .listen('NotificationRead', ({ notificationId }) => {
          this.total--

          const index = this.notifications.findIndex(n => n.id === notificationId)
          if (index > -1) {
            this.notifications.splice(index, 1)
          }
        })
        .listen('NotificationReadAll', e => {
          console.log(e)
          this.total = 0
          this.notifications = []
        })
    },

  },

}
</script>

<style>

</style>
