<template>
  <li
    v-if="checkedAclgaurd(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled
    }"
  >
    <b-link v-bind="linkProps" class="align-items-center" :class="{ 'd-flex': !ischild }">
      <span class="" :class="{ 'nav-icon-costm': !ischild }">
        <feather-icon :icon="item.icon || 'CircleIcon'" />
      </span>
      <span class="menu-title text-truncate" :class="{ 'nav-text-custom': !ischild }">
        <!-- {{ $can(item.action, item.resource) }} {{ item.action }} {{ item.resource }}  {{ canViewVerticalNavMenuLink(item) }} -->

        {{ t(item.title) }}</span
      >
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import store from "@/store";
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import { ref, toRefs, watchEffect, computed } from "@vue/composition-api";
export default {
  components: {
    BLink,
    BBadge
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true
    },
    ischild: {
      default: false
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();
    const contactType = computed(() => store.state.auth.contactType);
    const checkedAclgaurd = item => {
      if (item.guard) {
        if (item.guard == contactType.value) {
          return true;
        }
        return false;
      }
      return canViewVerticalNavMenuLink(item);
    };
    return {
      isActive,
      linkProps,
      updateIsActive,
      contactType,
      checkedAclgaurd,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t
    };
  }
};
</script>

<style>
/* .nav-icon-costm {
    display: block;
    font-size: 32px;
    height: 32px;
    margin: 0 auto 6px;
    width: 32px;
}

.nav-text-custom {
    display: block;
    font-size: 13px;
    font-weight: 400;
}*/
</style>
